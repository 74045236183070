<template>
    <div>{{$t("message.currentPoints")}}: {{riskPoints}}</div>

    <template v-for="p in allRiskClasses" :key="p.class">
        <!-- outer frame -->
        <div v-if="p.min <= p.max"
             class=" mt-1 mb-1 d-flex flex-row justify-content-between"
             :class="[p.active ? 'border border-2 ' + p.borderClass : '']"
             >

            <!-- inner frame -->
            <div :style="{width: p.adRisk + '%'}"
                 class="border p-1"
                 :class="[
                     p.active ? p.borderClass : '',
                     p.active ? p.bgClass : ''
                 ]">
            </div>

            <!-- points label (left) -->
            <div class="position-absolute p-1">
                {{p.min}} - {{p.max}} {{$t("message.points")}}
            </div>

            <!-- risk label (right) -->
            <div class="p-1">{{$t("message.risk")}}: {{p.adRisk}}%</div>
        </div>
    </template>

        <!--
        <div v-for="r in [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100]"
             :key="'k' + r"
             class="border p-1"
             :style="{backgroundColor: colorForAdRisk(r), fontWeight: 'bolder'}">
            <span class="fs-6 fw-lighter">{{r}}</span>
        </div>
        -->
</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {
        enumKeys,
        Risk,
        RiskDetails, riskForPoints
    } from "@/data/risk_score";

    const riskClasses: { [k in Risk]: string } = {
        [Risk.Low]: 'risk-1',
        [Risk.LowModerate]: 'risk-2',
        [Risk.Moderate]: 'risk-3',
        [Risk.ModerateHigh]: 'risk-4',
        [Risk.High]: 'risk-5',
    };

    export default defineComponent({
        name: 'RiskScore',
        props: {
            riskPoints: Number,
            riskDetails: {
                type: Object as () => { [k in Risk]: RiskDetails},
                default: () => {
                    //just some dummy risks as default prevent checking for undefined in following methods
                    return {
                        [Risk.Low]:             {'min': 0,  'max': 1,  'adRisk': 20},
                        [Risk.LowModerate]:     {'min': 2,  'max': 3,  'adRisk': 40},
                        [Risk.Moderate]:        {'min': 4,  'max': 5,  'adRisk': 60},
                        [Risk.ModerateHigh]:    {'min': 6,  'max': 7,  'adRisk': 80},
                        [Risk.High]:            {'min': 8,  'max': 9,  'adRisk': 99},
                    }
                }
            }
        },
        computed: {
            risk(): Risk {
                const points = this.riskPoints ?? 0;
                return riskForPoints(points, this.riskDetails);
            },
            globalMax(): number {
                return this.riskDetails[Risk.High].max;
            },
            allRiskClasses(): any {
                let result = [];
                for (let risk of enumKeys(Risk)) {
                    const bgRiskClass = 'bg-' + riskClasses[risk];
                    const borderRiskClass = 'bc-' + riskClasses[risk];
                    const riskDetails = this.riskDetails[risk];
                    const active = risk == this.risk;
                    result.push({
                        'bgClass': bgRiskClass,
                        'borderClass': borderRiskClass,
                        'min': riskDetails.min,
                        'max': riskDetails.max,
                        'adRisk': riskDetails.adRisk,
                        'color': this.colorForAdRisk(riskDetails.adRisk),
                        'active': active
                    });
                }
                return result;
            }
        },
        methods: {
            colorForAdRisk(adRisk: number) {
                if (adRisk < 0 || adRisk >= 100) {
                    return "#FF55AA";
                }
                const index = Math.floor(adRisk / 10);

                const RISK_COLORS = [
                    "#01A651",
                    "#82CC29",
                    "#C4DF76",
                    "#FFF600",
                    "#FFDD26",
                    "#FFC302",
                    "#F58814",
                    "#EF4625",
                    "#BF182C",
                    "#A72C2C",
                ]

                return RISK_COLORS[index];
            }
        }
    });
</script>

<style scoped>
    .bg-risk-5 {
        background-color: #BF182C;
    }

    .bg-risk-4 {
        background-color: #EF4625;
    }

    .bg-risk-3 {
        background-color: #F58814;
    }

    .bg-risk-2 {
        background-color: #82CC29;
    }

    .bg-risk-1 {
        background-color: #01A651;
    }

    .bc-risk-5 {
        border-color: #BF182C !important;
    }

    .bc-risk-4 {
        border-color: #EF4625 !important;
    }

    .bc-risk-3 {
        border-color: #F58814 !important;
    }

    .bc-risk-2 {
        border-color: #82CC29 !important;
    }

    .bc-risk-1 {
        border-color: #01A651 !important;
    }
</style>

    import {defineComponent} from 'vue';
    import {Field, BooleanField} from "@/data/fields";
    import {riskSumForFields} from "@/data/risk_score";

    function defaultActive() {
        let active = new Map<string, boolean | string>();
            // Setting these values here is a bit... hacky!
        active.set("ic_input_age", "ic_age_0");
        active.set("clti_input_age", "clti_age_0");
        return active;
    }

    export default defineComponent({
        name: 'RiskScore',
        props: {
            fields: {
                type: Array as () => Field[]
            }
        },
        emits: ['change-points'],
        data() {
            return {
                active: defaultActive()
            }
        },
        computed: {
            risk_points(): number {
                if (!this.fields) {
                    return 0;
                }
                else {
                    return riskSumForFields(this.fields, this.active);
                }
            },
        },
        methods: {
            activateField(field: Field, choice="") {
                if (this.isBooleanField(field)) {
                    const fieldActive = this.active.get(field.key);
                    const newFieldActive = !(fieldActive ?? false);
                    this.active.set(field.key, newFieldActive);
                }
                else {
                    this.active.set(field.key, choice);
                }
                this.$emit('change-points', this.risk_points);
            },
            isActive(fieldkey: string, choicekey=""): boolean {
                const fieldActive = this.active.get(fieldkey);
                if (fieldActive) {
                    // ugly hack, but this is the problem of using BooleanField and ChoiceField here :(
                    if (typeof  fieldActive == "boolean") {
                        return fieldActive;
                    }
                    else {
                        return choicekey == fieldActive;
                    }
                }
                else {
                    return false;
                }
            },
            isBooleanField(field: Field): boolean {
                return (field instanceof BooleanField);
            },
            resetFields() {
                this.active = defaultActive();
                this.$emit('change-points', this.risk_points);
            }
        },
    });

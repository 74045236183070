<template>
  <div class="container">

    <!-- HEAD -->
    <div class="row">
      <div class="col">
        <h1>{{$t("message.headline")}}</h1>
      </div>
      <div class="col-4 col-md-2 d-flex align-items-center justify-content-end">
        <Localization />
      </div>
    </div>

    <!-- INTRO -->
    <div class="row">
      <div v-html="$t('message.introText')" class="text-justify"></div>
    </div>

    <!-- SCORES -->
    <div class="row">
      <div class="container bg-light border mb-3 mt-3 pb-3 pt-3">
        <h1>
          {{$t("message.icCltiScoreHeading")}}
          <i class="fas fa-cross"></i>
        </h1>
        <hr />
        <IcCltiChooser @change-ic="icRisk = $event" />
        <div v-bind:class="[ icRisk ? 'visible' : 'hidden' ]">
          <Fields :fields="IC_INPUT_FIELDS" @change-points="icRiskPoints = $event"/>
          <h3>{{$t("message.icCltiRiskScoreHeader")}}</h3>
          <RiskScore :risk-details="IC_RISK_DETAILS" :risk-points="icRiskPoints"/>
        </div>
        <div v-bind:class="[ icRisk ? 'hidden' : 'visible' ]">
          <Fields :fields="CLTI_INPUT_FIELDS" @change-points="cltiRiskPoints = $event"/>
          <h3>{{$t("message.icCltiRiskScoreHeader")}}</h3>
          <RiskScore :risk-details="CLTI_RISK_DETAILS" :risk-points="cltiRiskPoints"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-html="$t('message.betweenScoreText')" class="text-justify"></div>
    </div>

    <div class="row">
      <div class="container bg-light border mb-3 mt-3 pb-3 pt-3">
        <h1>
          {{$t("message.bleedingScoreHeading")}}
          <i class="fas fa-tint text-danger"></i>
        </h1>
        <hr />
        <Fields :fields="BLEEDING_INPUT_FIELDS" @change-points="bleedingRiskPoints = $event"/>
        <h3>{{$t("message.bleedingRiskScoreHeader")}}</h3>
        <RiskScore :risk-details="BLEEDING_RISK_DETAILS" :risk-points="bleedingRiskPoints"/>
      </div>
    </div>

    <!-- OUTRO -->
    <div class="row">
      <div v-html="$t('message.outroText')" class="text-justify"></div>

      <div class="border bg-light rounded p-3 mb-3 mt-3">

        <p v-html="$t('message.methodologyIntro')"></p>
        <p class="text-justify ms-2 ps-2 border-start border-secondary border-2">
          Kreutzburg, T.; Peters, F.; Kuchenbecker, J.; Marschall, U.; Lee, R.; Kriston, L.; Debus, E. S. &amp; Behrendt, C. A. (2021).<br />
          <strong>Editor's Choice – The GermanVasc Score: A Pragmatic Risk Score Predicts Five Year Amputation Free Survival in Patients with Peripheral Arterial Occlusive Disease.</strong><br />
          European Journal of Vascular and Endovascular Surgery, 61(2), 248-256.<br />
          doi: <a href="https://doi.org/10.1016/j.ejvs.2020.11.013" target="_blank">10.1016/j.ejvs.2020.11.013</a>
          PubMed: <a href="https://pubmed.ncbi.nlm.nih.gov/33334671/" target="_blank">33334671</a>
        </p>

        <p class="text-justify ms-2 ps-2 border-start border-secondary border-2">
          Behrendt, C. A.; Kreutzburg, T.; Nordanstig, J.; Twine, C. P.; Marschall, U.; Kakkos, S.; Aboyans, V. &amp; Peters, F. (2022).<br />
          <strong>The OAC3-PAD Risk Score Predicts Major Bleeding Events one Year after Hospitalisation for Peripheral Artery Disease</strong><br />
          European Journal of Vascular and Endovascular Surgery (in press).<br />
          doi: <a href="https://doi.org/10.1016/j.ejvs.2021.12.019" target="_blank">10.1016/j.ejvs.2021.12.019</a>
<!--          PubMed: <a href="https://pubmed.ncbi.nlm.nih.gov/todo/" target="_blank">todo</a> -->
        </p>


        <div class="row">
          <div class="col-lg-6">
            <p v-html="$t('message.developedByHead')"></p>
            <p v-html="$t('message.developedBy')" class="ms-2 ps-2 border-start border-secondary border-2"></p>
          </div>

          <div class="col-lg-6">
            <p v-html="$t('message.responsible')"></p>
            <p class="mb-0 ms-2 ps-2 border-start border-secondary border-2">
              PD Dr. Christian-Alexander Behrendt<br />
              Postfach 201408<br />
              20204 Hamburg<br />
              <a href="mailto:info@germanvasc.de">info@germanvasc.de</a>
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RiskScore from './components/RiskScore.vue';
import Localization from './components/Localization.vue';
import Fields from './components/Fields.vue';
import IcCltiChooser from './components/IcCltiChooser.vue';
import {BLEEDING_INPUT_FIELDS, CLTI_INPUT_FIELDS, IC_INPUT_FIELDS} from "@/data/fields";
import {BLEEDING_RISK_DETAILS, CLTI_RISK_DETAILS, IC_RISK_DETAILS} from "@/data/risk_score";

export default defineComponent({
  name: 'App',
  components: {
    Localization,
    RiskScore,
    Fields,
    IcCltiChooser,
  },
  data() {
    return {
      icRisk: true,
      icRiskPoints: 0,
      cltiRiskPoints: 0,
      bleedingRiskPoints: 0,
      IC_INPUT_FIELDS: IC_INPUT_FIELDS,
      CLTI_INPUT_FIELDS: CLTI_INPUT_FIELDS,
      BLEEDING_INPUT_FIELDS: BLEEDING_INPUT_FIELDS,
      IC_RISK_DETAILS: IC_RISK_DETAILS,
      CLTI_RISK_DETAILS: CLTI_RISK_DETAILS,
      BLEEDING_RISK_DETAILS: BLEEDING_RISK_DETAILS,
    }
  }
});
</script>

<style>
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";

  .text-justify {
    text-align: justify;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
</style>

export interface Field {
    key: string;
    name: string;

    summand(input: boolean | string | undefined): number;
}

export class BooleanField implements Field {
    key: string;
    name: string;
    faIconKey: string;
    private points: number;

    constructor(key: string, name: string, summand: number, faIconKey="fa-user") {
        this.key = key;
        this.name = name;
        this.faIconKey = faIconKey;
        this.points = summand;
    }

    summand(input: boolean | undefined): number {
        return input ? this.points : 0;
    }
}

class Choice {
    key: string;
    name: string;
    faIconKey: string;
    points: number;

    constructor(key: string, name: string, points: number, faIconKey="fa-user") {
        this.key = key;
        this.name = name;
        this.faIconKey = faIconKey;
        this.points = points;
    }
}

export class ChoiceField implements Field {
    key: string;
    name: string;
    choices: Record<string, Choice>;

    constructor(key: string, name: string, choices: Choice[]) {
        this.key = key;
        this.name = name;
        this.choices = {};
        for (const c of choices) {
            this.choices[c.key] = c;
        }
    }

    summand(input: string | undefined): number {
        if (input && input != '') {
            const choice = this.choices[input];
            if (choice) {
                return choice.points;
            } else {
                console.error("ChoiceField.summand called with non-member choice key")
                return 0;
            }
        }
        else  {
            // no input -> field not activated at all
            return 0;
        }
    }

}

// These might be updated

export const IC_INPUT_FIELDS: Field[] = [
    new ChoiceField('ic_input_age', 'fields.age', [
        new Choice('ic_age_0', '<= 60', 0),
        new Choice('ic_age_1', '61 - 70', 4),
        new Choice('ic_age_2', '71 - 80', 8),
        new Choice('ic_age_3', '> 80', 16),
    ]),
    new BooleanField('ic_input_dialysis', 'fields.ic.dialysis', 12, 'fa-prescription-bottle-alt'),
    new BooleanField('ic_input_alcohol_abuse', 'fields.ic.alcohol_abuse', 6, 'fa-beer'),
    new BooleanField('ic_input_fluid_disorders', 'fields.ic.fluid_disorders', 5, 'fa-burn'),
    new BooleanField('ic_input_cancer', 'fields.ic.cancer', 5, 'fa-ribbon'),
    new BooleanField('ic_input_diabetes', 'fields.ic.diabetes', 4, 'fa-tint'),
    new BooleanField('ic_input_copd', 'fields.ic.copd', 4, 'fa-lungs'),
    new BooleanField('ic_input_male', 'fields.ic.male', 3, 'fa-male'),
    new BooleanField('ic_input_dyslipidaemia', 'fields.ic.dyslipidaemia', 2, 'fa-capsules'),
    new BooleanField('ic_input_prior_hospitalization', 'fields.ic.prior_hospitalization', 2, 'fa-hospital'),
];

export const CLTI_INPUT_FIELDS: Field[] = [
    new ChoiceField('clti_input_age', 'fields.age', [
        new Choice('clti_age_0', '<= 60', 0),
        new Choice('clti_age_1', '61 - 70', 3),
        new Choice('clti_age_2', '71 - 80', 6),
        new Choice('clti_age_3', '> 80', 11),
    ]),
    new BooleanField('clti_input_vascular_dementia', 'fields.clti.vascular_dementia', 7, 'fa-brain'),
    new BooleanField('clti_input_unspec_dementia', 'fields.clti.unspec_dementia', 6, 'fa-brain'),
    new BooleanField('clti_input_dialysis', 'fields.clti.dialysis', 6, 'fa-prescription-bottle-alt'),
    new BooleanField('clti_input_gangrene', 'fields.clti.gangrene', 4, 'fa-band-aid'),
    new BooleanField('clti_input_cancer', 'fields.clti.cancer', 4, 'fa-ribbon'),
    new BooleanField('clti_input_heart_failure', 'fields.clti.heart_failure', 3, 'fa-heart-broken'),
    new BooleanField('clti_input_fluid_disorders', 'fields.clti.fluid_disorders', 3, 'fa-burn'),
    new BooleanField('clti_input_renal_failure', 'fields.clti.renal_failure', 2, 'fa-flask'),
    new BooleanField('clti_input_arrhythmias', 'fields.clti.arrhythmias', 2, 'fa-heartbeat'),
];

export const BLEEDING_INPUT_FIELDS: Field[] = [
    new BooleanField('bleed_input_oral_anticoagulation', 'fields.bleed.oral_anticoagulation', 5, 'fa-pills'),
    new BooleanField('bleed_input_age_greater_than_80', 'fields.bleed.age_greater_than_80', 2, 'fa-user'),
    new BooleanField('bleed_input_chronic_limb_threatening_ischaemia', 'fields.bleed.chronic_limb_threatening_ischaemia', 4, 'fa-wheelchair'),
    new BooleanField('bleed_input_congestive_heart_failure', 'fields.bleed.congestive_heart_failure', 3, 'fa-heart-broken'),
    new BooleanField('bleed_input_chronic_kidney_disease', 'fields.bleed.chronic_kidney_disease', 3, 'fa-flask'),
    new BooleanField('bleed_input_prior_bleeding_event', 'fields.bleed.prior_bleeding_event', 6, 'fa-tint'),
    new BooleanField('bleed_input_anaemia', 'fields.bleed.anaemia', 8, 'fa-battery-empty'),
    new BooleanField('bleed_input_dementia', 'fields.bleed.dementia', 3, 'fa-brain'),
];
import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import {Tooltip} from 'bootstrap';

// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: { [key: string]: any} = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  })
  return messages
}

const app = createApp(App);
const i18n = createI18n({
    locale: 'de',
    messages: loadLocaleMessages()
  // something vue-i18n options here ...
});

function tooltip(el: Element) {
    const t = new Tooltip(el, {
        placement: 'top',
        trigger: 'hover',
    });
    el.addEventListener('shown.bs.tooltip', function () {
        setTimeout(() => { t.hide(); }, 5000);
    })

    return t;
}

app.directive('tooltip', {
        mounted(el) {
            return tooltip(el);
        },
        updated(el) {
            // updated is required when the elements change, in our case this is required for title localization
            return tooltip(el);
        }
    }
);

app.use(i18n);
app.mount('#app');

<!--
Details can be found here: https://vue-i18n.intlify.dev/introduction.html
-->

<template>
    <div class="locale-changer">
        <i class="fas fa-globe me-3"></i>
        <select v-model="$i18n.locale">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
        </select>
    </div>
</template>

<script>
    export default {
        name: "Localization"
    }
</script>

<style scoped>

</style>
<template>
    <h3>{{$t("message.diseaseHeader")}}</h3>
    <div class="d-grid gap-2 mb-3">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button"
                    class="btn p-1"
                    :class="icRisk? 'btn-primary' : 'btn-outline-secondary'"
                    @click="setIcIctl(true)">
                <i class="fas fa-comment-medical"></i><br/>
                {{$t("message.icAbbr")}}<br>
                {{$t("message.icFull")}}
                <i v-tooltip :title="$t('message.icInfo')" class="fas fa-info-circle ml-3"></i>
            </button>
            <button type="button"
                  class="btn p-1"
                  :class="icRisk? 'btn-outline-secondary': 'btn-primary'"
                  @click="setIcIctl(false)">
                <i class="fas fa-comment-medical"></i><br />
                {{$t("message.cltiAbbr")}}<br>
                {{$t("message.cltiFull")}}
                <i v-tooltip :title="$t('message.cltiInfo')" class="fas fa-info-circle ml-3"></i>
          </button>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from "vue";

    export default defineComponent({
        name: 'IcCltiChooser',
        emits: ['change-ic'],
        data() {
            return {
                icRisk: true,
            };
        },
        methods: {
            setIcIctl(ic: boolean) {
                this.icRisk = ic;
                this.$emit('change-ic', this.icRisk);
            }
        }
    });
</script>

<style scoped>

</style>

import { defineComponent } from 'vue';
import RiskScore from './components/RiskScore.vue';
import Localization from './components/Localization.vue';
import Fields from './components/Fields.vue';
import IcCltiChooser from './components/IcCltiChooser.vue';
import {BLEEDING_INPUT_FIELDS, CLTI_INPUT_FIELDS, IC_INPUT_FIELDS} from "@/data/fields";
import {BLEEDING_RISK_DETAILS, CLTI_RISK_DETAILS, IC_RISK_DETAILS} from "@/data/risk_score";

export default defineComponent({
  name: 'App',
  components: {
    Localization,
    RiskScore,
    Fields,
    IcCltiChooser,
  },
  data() {
    return {
      icRisk: true,
      icRiskPoints: 0,
      cltiRiskPoints: 0,
      bleedingRiskPoints: 0,
      IC_INPUT_FIELDS: IC_INPUT_FIELDS,
      CLTI_INPUT_FIELDS: CLTI_INPUT_FIELDS,
      BLEEDING_INPUT_FIELDS: BLEEDING_INPUT_FIELDS,
      IC_RISK_DETAILS: IC_RISK_DETAILS,
      CLTI_RISK_DETAILS: CLTI_RISK_DETAILS,
      BLEEDING_RISK_DETAILS: BLEEDING_RISK_DETAILS,
    }
  }
});

import {Field} from "@/data/fields";

export enum Risk {
    Low = "Low",
    LowModerate = "LowModerate",
    Moderate = "Moderate",
    ModerateHigh = "ModerateHigh",
    High = "High",
}

export function riskSumForFields(fields: Field[], active: Map<string, boolean | string>): number {
    let result = 0;
    for (const field of fields) {
        const fieldActive = active.get(field.key);
        result += field.summand(fieldActive);
    }
    return result;
}

export function enumKeys<O extends Record<string, unknown>, K extends keyof O = keyof O>(obj: O): K[] {
    // Found here: https://www.petermorlion.com/iterating-a-typescript-enum/
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function riskForPoints(sum: number, riskDetails: {[k in Risk]: RiskDetails}): Risk {
    for (const r of enumKeys(Risk)) {
        const risk: Risk = Risk[r];
        const riskDetailsForRisk = riskDetails[risk];
        if (sum <= riskDetailsForRisk.max) {
            return risk;
        }
    }

    return Risk.High;
}

// These might be updated

export interface RiskDetails {
    min: number;
    max: number;
    adRisk: number;
}

export const IC_RISK_DETAILS: { [k in Risk]: RiskDetails} = {
    [Risk.Low]:             {'min': 0,  'max': 7,  'adRisk': 9},
    [Risk.LowModerate]:     {'min': 8,  'max': 10, 'adRisk': 13},
    [Risk.Moderate]:        {'min': 11, 'max': 14, 'adRisk': 19},
    [Risk.ModerateHigh]:    {'min': 15, 'max': 19, 'adRisk': 29},
    [Risk.High]:            {'min': 20, 'max': 49, 'adRisk': 48},
}

export const CLTI_RISK_DETAILS: { [k in Risk]: RiskDetails} = {
    [Risk.Low]:             {'min': 0,  'max': 7,  'adRisk': 25},
    [Risk.LowModerate]:     {'min': 8,  'max': 12, 'adRisk': 46},
    [Risk.Moderate]:        {'min': 13, 'max': 15, 'adRisk': 59},
    [Risk.ModerateHigh]:    {'min': 16, 'max': 20, 'adRisk': 74},
    [Risk.High]:            {'min': 21, 'max': 48, 'adRisk': 88},
}

export const BLEEDING_RISK_DETAILS: { [k in Risk]: RiskDetails} = {
    [Risk.Low]:             {'min': 0,  'max': 0,  'adRisk': 1.28},
    [Risk.LowModerate]:     {'min': 1,  'max': 4, 'adRisk': 1.79},
    [Risk.Moderate]:        {'min': 5, 'max': 4, 'adRisk': 0},
    [Risk.ModerateHigh]:    {'min': 5, 'max': 9, 'adRisk': 2.62},
    [Risk.High]:            {'min': 10, 'max': 33, 'adRisk': 6.42},
}
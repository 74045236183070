
    import {defineComponent} from "vue";

    export default defineComponent({
        name: 'IcCltiChooser',
        emits: ['change-ic'],
        data() {
            return {
                icRisk: true,
            };
        },
        methods: {
            setIcIctl(ic: boolean) {
                this.icRisk = ic;
                this.$emit('change-ic', this.icRisk);
            }
        }
    });

<template>
    <div class="row mb-3">
        <div class="col">
        <h3>{{$t("message.riskFactorsHeader")}}</h3>
        </div>
        <div class="col d-flex justify-content-end">
            <button class="btn btn-sm btn-outline-secondary" @click="resetFields()">
                <i class="fas fa-undo-alt"></i>
                {{$t('message.reset')}}
            </button>
        </div>
    </div>

    <div class="row gx-3 gy-3 mb-3">
        <template v-for="field in fields" :key="field.key">
            <div v-if="isBooleanField(field)" class="col-6 col-md-4 col-lg-3 d-grid">
                <button type="button"
                        class="btn p-1"
                        :class="isActive(field.key) ? 'btn-danger' : 'btn-outline-secondary'"
                        @click="activateField(field)">
                    <i class="fs-3 fas" :class="field.faIconKey"></i><br />
                    {{ $t(field.name) }}
                    <i v-tooltip :title="$t(field.name + '_info')" class="fas fa-info-circle ml-3"></i><br>
                    <span class="text-sm"> (+{{field.points}}) </span>
                </button>
            </div>

            <div class="d-flex col-12" v-else>
                <!--<div class="float-start d-flex align-items-center justify-content-center" >
                    <div class="m-1 rotated">{{$t(field.name)}}</div>
                </div>-->
                <div class="btn-group flex-fill" role="group">
                    <div class="p-3 border border-secondary text-secondary rounded-start d-flex justify-content-center align-items-center">
                        <div class="">
                            {{ $t(field.name) }}
                            <i v-tooltip :title="$t(field.name + '_info')" class="fas fa-info-circle ml-3"></i>
                        </div>
                    </div>
                    <button type="button"
                            class="btn p-1"
                            :class="isActive(field.key, choice.key) ? 'btn-primary' : 'btn-outline-secondary'"
                            v-for="choice in field.choices"
                            :key="choice.key"
                            @click="activateField(field, choice.key)">
                        <i class="fs-3 fas" :class="choice.faIconKey"></i><br />
                        {{ $t(choice.name) }} <br>
                        <span class="text-sm"> (+{{choice.points}}) </span>
                    </button>
                </div>
            </div>
        </template>
    </div>


</template>

<script lang="ts">
    import {defineComponent} from 'vue';
    import {Field, BooleanField} from "@/data/fields";
    import {riskSumForFields} from "@/data/risk_score";

    function defaultActive() {
        let active = new Map<string, boolean | string>();
            // Setting these values here is a bit... hacky!
        active.set("ic_input_age", "ic_age_0");
        active.set("clti_input_age", "clti_age_0");
        return active;
    }

    export default defineComponent({
        name: 'RiskScore',
        props: {
            fields: {
                type: Array as () => Field[]
            }
        },
        emits: ['change-points'],
        data() {
            return {
                active: defaultActive()
            }
        },
        computed: {
            risk_points(): number {
                if (!this.fields) {
                    return 0;
                }
                else {
                    return riskSumForFields(this.fields, this.active);
                }
            },
        },
        methods: {
            activateField(field: Field, choice="") {
                if (this.isBooleanField(field)) {
                    const fieldActive = this.active.get(field.key);
                    const newFieldActive = !(fieldActive ?? false);
                    this.active.set(field.key, newFieldActive);
                }
                else {
                    this.active.set(field.key, choice);
                }
                this.$emit('change-points', this.risk_points);
            },
            isActive(fieldkey: string, choicekey=""): boolean {
                const fieldActive = this.active.get(fieldkey);
                if (fieldActive) {
                    // ugly hack, but this is the problem of using BooleanField and ChoiceField here :(
                    if (typeof  fieldActive == "boolean") {
                        return fieldActive;
                    }
                    else {
                        return choicekey == fieldActive;
                    }
                }
                else {
                    return false;
                }
            },
            isBooleanField(field: Field): boolean {
                return (field instanceof BooleanField);
            },
            resetFields() {
                this.active = defaultActive();
                this.$emit('change-points', this.risk_points);
            }
        },
    });
</script>

<style scoped>
    .rotated {
        transform: rotate(0.75turn);
    }
</style>